.condition {
    position: relative;
    padding-top: 200px;
    padding-bottom: 200px;
    min-height: 100vh;
    background: $color-yellow;

    @media (max-width: 1740px) {
        padding-top: 160px;
        padding-bottom: 100px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 445px;
        height: 836px;
        background: url("./../img/bg/cond-phone.png");

        @media (max-width: 1740px) {
            display: none;
        } 
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 332px;
        height: 252px;
        background: url("./../img/bg/cond-money.png");

        @media (max-width: 1740px) {
            display: none;
        }
    }

    &__paragraph {
        display: block;
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        text-align: center;
    }
}