.team {
    padding: 100px 0;
    background: $color-yellow;

    @media (max-width: $container-width-md) {
        padding: 40px 0;
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(33.3333% - 100px);
            text-align: center;

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 50px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &-img {
                margin-bottom: 30px;
                width: 160px;
                height: 160px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            &-name {
                margin-bottom: 12px;
                font: bold 24px $font-main;

                @media (max-width: $container-width-md) {
                    font-size: 18px;
                }
            }

            &-pg {
                font-size: $font-size-pg;
            }
        }
    }
}