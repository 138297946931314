.key {
    position: relative;
    padding-bottom: 150px;
    z-index: 20;

    @media (max-width: $container-width-md) {
        padding-bottom: 0;
    }

    &__wrap {
        @media (max-width: $container-width-md) {
            background: $color-yellow;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 100px;
        padding-bottom: 0;
        background: $color-yellow;

        @media (max-width: $container-width-md) {
            padding: 30px 20px;
        }

        &-item {
            margin-bottom: 100px;
            width: calc(50% - 100px);
            text-align: center;

            @media (max-width: $container-width) {
                width: calc(50% - 20px);
            }

            @media (max-width: $container-width-md) {
                width: calc(50% - 10px);
                margin-bottom: 30px;

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    margin-bottom: 0;
                }
            }

            &-caption {
                font-size: 20px;

                @media (max-width: $container-width-md) {
                    font-size: 12px;
                }
            }

            &-number {
                font-size: 48px;
                font-weight: bold;

                @media (max-width: $container-width-md) {
                    font-size: 20px;
                }
            }
        }
    }
}