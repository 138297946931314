.faq {
    position: relative;
    padding-top: 100px;
    padding-bottom: 150px;
    background: $color-yellow;
    overflow: hidden;

    @media (max-width: $container-width-md) {
        padding: 40px 0;
    }

    &:before {
        content: "";
        position: absolute;
        left: -110px;
        bottom: 93px;
        width: 343px;
        height: 461px;
        background: url("./../img/bg/coins.png");

        @media (max-width: 1600px) {
            display: none;
        }
    }

    &__bg {
        position: absolute;
        right: -200px;
        bottom: 220px;
        width: 597px;
        height: 1015px;
        background: url("./../img/bg/phone.png");

        @media (max-width: 1600px) {
            display: none;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &-item {
            margin-bottom: 60px;
            width: calc(33.3333% - 40px);
            text-align: center;

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 25px;
            }

            &-title {
                width: 100%;
                max-width: 260px;
                margin: 0 auto;
                margin-bottom: 15px;
                font-weight: bold;
                font-size: 18px;
                text-decoration: underline;
                cursor: pointer;

                &.active + .faq__list-item-desc {
                    display: block;
                }

                @media (max-width: $container-width-md) {
                    font-size: 16px;
                }
            }

            &-desc {
                display: none;
                font-size: $font-size-pg;
            }
        }
    }
}