.stats {
    padding-top: 100px;

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(16% - 30px);
            text-align: center;

            &:first-of-type {
                width: 20%;
            }

            @for $i from 1 through 6 {
                &:nth-of-type(#{$i}) {
                    order: #{$i};
                }
            }

            @media (max-width: $container-width) {
                margin-bottom: 60px;
                width: 33.3333% !important;

                &:first-of-type {
                    order: 3;
                }
            }

            @media (max-width: $container-width-md) {
                width: 50% !important;

                &:first-of-type {
                    order: 0;
                }
            }

            &-img {
                margin-bottom: 10px;
                width: 75px;
                height: 75px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            &-title {
                margin-bottom: 10px;
                font-weight: bold;
                font-size: $font-size-pg;
                color: $color-yellow;
            }

            &-value {
                font-weight: bold;
                font-size: 20px;
                color: #fff;
            }
        }
    }
}