.features {
    position: relative;
    padding: 100px 0;
    background: $color-yellow;
    overflow: hidden;

    @media (max-width: $container-width-md) {
        padding: 30px 0;
    }

    &:before {
        content: "";
        position: absolute;
        left: -130px;
        top: 50%;
        transform: translateY(-50%);
        width: 343px;
        height: 461px;
        background: url("./../img/bg/coins.png");

        @media (max-width: 1360px) {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: -130px;
        top: 50%;
        transform: translateY(-50%);
        width: 343px;
        height: 461px;
        background: url("./../img/bg/coins.png");

        @media (max-width: 1360px) {
            display: none;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 40px;

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 35px;
            width: calc(50% - 17px);
            padding: 35px;
            border: 2px solid $color-black;
            border-radius: 20px;

            @media (max-width: $container-width-md) {
                width: 100%;
                padding: 20px;
            }

            &-img {
                width: 48px;
                margin-right: 20px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100;
                }
            }

            &-desc {
                width: calc(100% - 68px);
                font-size: $font-size-pg;
            }
        }
    }
}