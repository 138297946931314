.table-wrap {

}

.table {
    width: calc(100% + 100px);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 -50px;
    padding: 30px 50px;

    @media (max-width: $container-width-md) {
        width: calc(100% + 26px);
        margin: 0 -13px;
        padding: 0 13px;
        padding-top: 15px;
    }

    th, td {
        text-align: left;
    }

    th {
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 20px;

        @media (max-width: $container-width-md) {
            padding-bottom: 10px;
            font-size: 10px;
        }
    }

    td {
        padding-bottom: 14px;
        font-size: $font-size-pg;

        @media (max-width: $container-width-md) {
            padding-bottom: 8px;
            font-size: 10px;
        }
    }

    &__filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 40px;

        @media (max-width: $container-width-md) {
            padding-top: 0;
            padding-bottom: 15px;
            align-items: flex-end;
        }

        &-value {
            font-weight: bold;
            font-size: 24px;

            @media (max-width: $container-width) {
                font-size: 16px;
                padding-right: 15px;
            }

            @media (max-width: $container-width) {
                font-size: 11px;
            }
        }

        &-title {
            margin-right: 25px;
            font-weight: bold;
            font-size: 20px;

            @media (max-width: $container-width) {
                font-size: 16px;
            }

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 5px;
                font-size: 10px;
                text-align: center;
                margin-right: 0;
            }
        }

        &-period {
            display: flex;
            align-items: center;

            @media (max-width: $container-width-md) {
                flex-wrap: wrap;
            }

            input {
                margin-right: 25px;
                padding: 10px 20px;
                border: 0;
                background: #fff;
                box-shadow: none;
                font-weight: bold;
                font-size: 14px;
                border-radius: 16px;
                text-align: center;

                @media (max-width: $container-width) {
                    margin-right: 15px;
                }

                @media (max-width: $container-width-md) {
                    width: calc(50% - 10px);
                    margin-right: 5px;
                    font-size: 10px;
                    padding: 3px 5px;
                }
            }

            @media (max-width: $container-width) {
                padding-right: 15px;
            }

            @media (max-width: $container-width-md) {
                padding-right: 0;
            }
        }

        &-coin {
            display: flex;
            align-items: center;

            @media (max-width: $container-width-md) {
                flex-wrap: wrap;
            }

            select {
                -moz-appearance: window;
                -webkit-appearance: none;
                padding: 10px 20px;
                border: 0;
                background: #fff;
                box-shadow: none;
                font-weight: bold;
                font-size: 14px;
                border-radius: 16px;
                text-align: center;

                @media (max-width: $container-width-md) {
                    width: 100%;
                    font-size: 10px;
                    padding: 5px 10px;
                }
            }
        }
    }
}