.tabs {
    padding: 100px 0;

    @media (max-width: $container-width-md) {
        padding: 30px 0;
    }

    &__list {
        display: flex;
        flex-direction: row;
        background: $color-yellow;
        border-radius: 20px 20px 0 0;
        overflow: hidden;

        @media (max-width: $container-width-md) {
            margin: 0 -15px;
        }

        &-item {
            position: relative;
            width: 140px;
            font-weight: bold;
            font-size: $font-size-pg;
            cursor: pointer;
            z-index: 2;

            span {
                display: block;
                padding: 20px;
                background: #fff;

                @media (max-width: $container-width-md) {
                    font-size: 11px;
                    padding: 13px !important;
                    width: auto;
                }
            }

            &:first-of-type {
                span {
                    padding-left: 50px;
                }
            }

            &:last-of-type {
                width: calc(100% - 420px);
                border-radius: 0 20px 20px 0;

                @media (max-width: $container-width-md) {
                    width: 100%;
                }
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                text-align: center;
            }

            &--active {
                span {
                    background: $color-yellow;
                    z-index: 20;
                    border-radius: 20px 20px 0 0;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                    z-index: -1;
                }

                & + .tabs__list-item span {
                    border-radius: 0 0 0 20px;
                }
            }
        }
    }

    &__blocks {
        padding: 50px;
        padding-top: 20px;
        background: $color-yellow;
        border-radius: 0 0 20px 20px;

        @media (max-width: $container-width-md) {
            margin: 0 -15px;
            padding: 13px;
        }

        &-item {

        }
    }
}