.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    height: 80px;
    background: $color-yellow;
    z-index: 1000;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);

    .container {
        position: static;
    }

    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 90px;

        @media (max-width: $container-width-md) {
            padding: 0;
        }
    }

    &__list {
        display: flex;

        @media (max-width: $container-width-md) {
            position: absolute;
            left: 0;
            right: 0;
            top: 80px;
            background: $color-yellow;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 15px 0;
            box-shadow: 0 14px 10px rgba(0, 0, 0, 0.04);
            display: none;
        }

        &-item {
            @extend %transition;
            
            display: flex;
            align-items: center;
            margin: 0 60px;
            color: $color-black;
            font-weight: 500;
            font-size: 16px;
            text-decoration: none;

            &--lang {

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            &--active, &:hover {
                opacity: 0.5;
            }

            @media (max-width: $container-width-md) {
                padding: 15px 0;
            }
        }
    }

    &__logo {

    }

    &__burger {
        display: none;
        margin-left: auto;
        cursor: pointer;

        @media (max-width: $container-width-md) {
            display: block;
        }

        img {
            object-fit: contain;
        }
    }
}