.graph {
    padding-top: 50px;
    padding-bottom: 200px;

    @media (max-width: $container-width-md) {
        padding-bottom: 40px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__itself {
        width: calc(70% - 50px);

        @media (max-width: $container-width) {
            width: calc(65% - 20px);
        }

        @media (max-width: $container-width-md) {
            width: 100%;
            order: 2;
        }
    }

    &__filter {
        width: calc(30% - 50px);

        @media (max-width: $container-width) {
            width: calc(35% - 20px);
        }

        @media (max-width: $container-width-md) {
            width: 100%;
            order: 1;
        }

        &-item {
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;

            &-title {
                margin-top: -4px;
                margin-right: 40px;
                font-weight: bold;
                font-size: 20px;
                color: $color-yellow;
            }
        }
    }
}