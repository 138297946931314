@import "extends";

html, body {
    position: relative;
    font: normal $font-size-pg $font-main;
}

body {
    background: $color-black;
    overflow-x: hidden;
}

.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}