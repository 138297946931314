.dropdown {
    width: 100%;

    &--radio {

    }

    &__title {
        padding: 8px 10px;
        position: relative;
        padding-right: 30px;
        background: $color-yellow;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;

        span {

        }

        img {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
    }

    &__list {
        background: $color-yellow;
        border-radius: 0 0 20px 20px;
        padding-bottom: 15px;
        display: none;

        &-item {
            position: relative;
            padding: 8px 10px;
            padding-left: 50px;
            text-align: center;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
                border: 2px solid $color-black; 
            }

            &:after {
                content: "";
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                background: $color-black;
                display: none;
            }

            &--active {
                &:after {
                    display: block;
                }
            }
        }

        &--active {
            display: block;
        }
    }
}