.search {
    padding-top: 160px;

    input {
        width: 100%;
        padding: 20px 40px;
        font-weight: 500;
        font-size: 28px;
        color: rgba(#fff, 0.5);
        background: rgba(#C4C4C4, 0.2);
        border-radius: 20px;
        border: 0;
        box-shadow: 0;
        appearance: none;

        @media (max-width: $container-width-md) {
            font-size: 16px;
            padding: 14px 30px;
        }
    }
}