.footer {
    padding-top: 60px;
    padding-bottom: 90px;

    @media (max-width: $container-width-md) {
        padding-bottom: 15px;
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        &-section {
            display: flex;
            flex-direction: column;
            width: calc(25% - 50px);

            @media (max-width: $container-width-md) {
                width: 100%;
                margin-bottom: 30px;
            }
            
            &-title {
                margin-bottom: 40px;
                font-weight: bold;
                font-size: 20px;
                color: $color-yellow;

                @media (max-width: $container-width-md) {
                    margin-bottom: 20px;
                }
            }

            &-link {
                display: block;
                margin-bottom: 20px;
                font-size: $font-size-pg;
                text-decoration: none;
                color: $color-yellow;
            }
        }
    }

    &__logo {
        margin-top: auto;
        width: 100%;
        max-width: 226px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}