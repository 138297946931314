.button {
    @extend %transition;

	display: inline-block;
	padding: 14px 60px;
	background: transparent;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	font: bold 20px $font-main;
	color: $color-black;
	white-space: nowrap;
	text-decoration: none;
	border-radius: 10px;

    &--bg {
        &-yellow {
            border-radius: 1px solid $color-black;;
            background: $color-yellow;

            &:hover {
                background: darken($color-yellow, 10%);
            }
        }

        &-black {
            color: $color-yellow;
            border-radius: 1px solid $color-yellow;
            background: $color-black;

            &:hover {
                background: lighten($color-black, 12%);
            }
        }
    }

    &--shadow {
        box-shadow: 0px 4px 20px rgba(230, 167, 47, 0.41), 0px 4px 4px rgba(0, 0, 0, 0.25);

        &:hover {
            box-shadow: 0px 4px 25px rgba(230, 167, 47, 0.6), 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    &-center {
        text-align: center;
    }
}