.mission {
    padding-top: 30px;
    padding-bottom: 100px;

    @media (max-width: $container-width-md) {
        padding-bottom: 40px;
    }

    &__img {
        width: 100%;
        max-width: 390px;
        margin: 0 auto;
        margin-bottom: 40px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__pg {
        margin: 0 auto;
        width: 100%;
        max-width: 800px;
        text-align: center;
        font-size: $font-size-pg;
        line-height: 1.6;
        color: #fff;
    }
}