.title {
    position: relative;
    margin-top: 0;
    margin-bottom: 60px;
    font: bold $font-size-hl $font-main;

    @media (max-width: $container-width-md) {
        font-size: 24px;
        margin-bottom: 30px;
    }

    &--color {
        &-yellow {
            color: $color-yellow;
        }

        &-black {
            color: $color-black;
        }
    }

    &--underlined {
        &:after {
            content: "";
            position: absolute;
            bottom: -30px;
            width: 360px;
            height: 4px;
            left: 50%;
            transform: translateX(-50%);
            background: $color-yellow;

            @media (max-width: $container-width-md) {
                width: 200px;
                bottom: -16px;
                height: 3px;
            }
        }
    }
}