.pop-up {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.7);

	&__panel {
		position: relative;
		width: 100%;
		max-width: 500px;
		padding: 40px;
		background: #fff;
		border-radius: 0px;
		max-height: calc(100vh - 40px);
		overflow-y: auto;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	}

	&__close {
		@extend %transition;
		
		position: absolute;
		top: 29px;
		right: 40px;
		font: normal 40px $font-main;
		color: $color-black;
		cursor: pointer;
		transform: rotate(45deg);

		&:hover {
			opacity: 0.7;
		}
	}

	&__title {
		margin-bottom: 20px;
		padding-right: 40px;
		font: 900 24px $font-main;
		color: $color-black;
	}

	&__desc {
		margin-bottom: 25px;
		font: normal 16px $font-main;
		line-height: 1.5;
		color: $color-black;

		a {
			@extend %transition;
			
			color: darken($color-yellow, 25%);
			text-decoration: none;

			&:hover, &:focus {
				color: $color-yellow;
				text-decoration: underline;
			}
		}
	}

	.button {
		font-size: 16px;
	}
}

.form {
	&__button {
		display: flex;
		margin-top: 40px !important;
		width: 100%;

		&--center {
			.button {
				margin: 0 auto;
			}
		}
	}

	&__title {
		width: 100%;
		display: block;
		margin-bottom: 20px;
		font: bold 24px $font-main;
		color: #fff;

		@media (max-width: 768px) {
			font-size: 20px;
		}

		&--center {
			text-align: center;
		}
	}

	&__input {
		margin-bottom: 15px;

		input, textarea {
			display: block;
			width: 100%;
			padding: 14px 20px;
			background: #fff;
			border: 0;
			font: normal 14px $font-main;
			border-radius: 50px;
			color: $color-black;
			resize: none;

			&::placeholder {
				color: $color-black;
			}
		}

		textarea {
			border-radius: 25px;
		}
	}

	&--dark {
		.form__input{
			input, textarea {
				border: 1px solid;
				border-color: $color-yellow;
				color: $color-black;

				&::placeholder {
					color: $color-black;
				}
			}
		}
	}

	&--inline {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;

		.form__input {
			margin-right: 20px;
			margin-bottom: 0;
			flex-grow: 1;
		}
	}
}

input.error,
textarea.error {
	border-radius: 4px 4px 0 0 !important;
	//border-color: rgb(229, 56, 53) !important;
}

input + label.error,
textarea + label.error {
	background-color: rgb(229, 56, 53);
	color: #fff;
	display: block;
	font: 300 12px $font-main;
	padding: 2px 4px;
	z-index: 2;
	border-radius: 0 0 4px 4px;
}

.body-fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
