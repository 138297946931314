.header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
    height: 100vh;
    min-height: 500px;
    background: $color-black;
    overflow: visible;
    overflow-x: hidden;

    @media (max-width: $container-width-md) {
        height: auto;
        padding-top: 200px;
        padding-bottom: 50px;
    }

    &__bg {
        position: absolute;
        right: 0;
        top: 100px;
        width: 973px;
        height: auto;
        z-index: 10;
        transform: translateX(60%);
        top: 25vh;

        @media (max-width: 1500px) {
            transform: none;
            width: 500px;
        }

        @media (max-width: 1199px) {
            width: 400px;
            transform: translateX(30%);
        }

        @media (max-width: $container-width-md) {
            display: none;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: auto;;
        }
    }

    &__bg-mob {
        display: none;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        @media (max-width: $container-width-md) {
            display: block;
            margin-bottom: 25px;
        }
    }

    .container {
        margin-top: -80px;
        z-index: 20;
    }

    &__title {
        margin-bottom: 16px;
        font-weight: 900;
        font-size: 56px;
        color: $color-yellow;
        text-transform: uppercase;

        @media (max-width: $container-width-md) {
            font-size: 30px;
            text-align: center;
            margin-bottom: 8px;
        }
    }

    &__subtitle {
        margin-bottom: 60px;
        font-size: 20px;
        color: #fff;

        @media (max-width: $container-width-md) {
            font-size: 16px;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    @media (max-width: $container-width-md) {
        .button {
            width: 100%;
            text-align: center;
        }
    }
}