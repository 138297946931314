$bg-color: #212121;
$default-size: 1em;
$label-font-size: 16px;
$label-font-size-redo: 40px;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

.set-size {
  font-size: 10em;
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  position: relative;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 3.5;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: $label-font-size-redo;
    font-weight: bold;
    left: $label-font-size-redo / 3.5;
    line-height: $label-font-size-redo * .70;
    position: absolute;
    right: $label-font-size-redo / 3.5;
    text-align: center;
    top: $label-font-size-redo / 3.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  @for $i from 0 through 100 {
    &.progress-#{$i} {
      @include draw-progress($i, #D33838);
    }
  }
}

